import { ShopwareProduct } from '@services/types/Shopware';

class StorefrontApiService {
    basePath:  string;

    constructor() {
        this.basePath = '';
    }

    getHeaders() {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };
    }

    apiPath(path: string) {
        return `${this.basePath}/api/${path}`;
    }

    async get(path: string) {
        let response = await fetch(this.apiPath(path), {
            method: 'GET',
            headers: this.getHeaders(),
            credentials: 'include',
        });

        if (response.status >= 400) {
            return { status: 'error', data: null, response: response };
        }

        return await response.json();
    }

    async delete(path: string) {
        let response = await fetch(this.apiPath(path), {
            method: 'DELETE',
            headers: this.getHeaders(),
            credentials: 'include',
        });

        if (response.status >= 400) {
            return { status: 'error', data: null, response: response };
        }

        return response;
    }

    async post(path: string, data: any) {
        let response = await fetch(this.apiPath(path), {
            method: 'POST',
            headers: this.getHeaders(),
            credentials: 'include',
            body: JSON.stringify(data),
        });

        if (response.status >= 400) {
            return { status: 'error', data: null, response: response };
        }

        return await response.json();
    }

    async getProductById(productId: string) {
        return this.get(`product/get/by-id/${productId}`);
    }

    async getProductByIds(productIds: Array<string>): Promise<{products: ShopwareProduct[], status: string}> {
        // @ts-ignore
        return this.get(`product/get/by-ids?ids=${productIds.map((p) => p.replaceAll('-', '')).join(',')}`);
    }

    async fetchMarketingLabels() {
        return this.get('properties/labels');
    }

    async getVariants(parentId: string) {
        return this.get(`product/get/variants/${parentId}`);
    }

    /**
     * @param productIds
     * @returns {Promise<{cart: Object|null}|any>}
     */
    async addItemsToCart(productIds: Array<string>) {
        return this.post('cart/add-items', {
           productIds
        });
    }
}

export default StorefrontApiService;
